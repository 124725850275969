export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            light: false,
            colors: {
              background: "#eceaea",
              surface: "#005678",
              footerLink: "#A2C4C9",
              promoCategoriesLinks: "FFFFFF",
              // rating: "#f4c74d",
              // cardTitle: "#6a040f", // "#ffeeba",
              linkColor: "#3e37ff",
              // jackpot: "#007bff",
              orangeButton: "#ff6a65",
              iconColor: "FFFFFF",
              toTop: "#5b89a0",
            },
          },
          dark: {},
        },
      },
    });
    // vuetify.vuetifyOptions.icons.aliases.ratingFull = vuetify.vuetifyOptions.icons.aliases.cardsSpade;
    // vuetify.vuetifyOptions.icons.aliases.ratingEmpty = vuetify.vuetifyOptions.icons.aliases.cardsSpadeOutline;
  });
});
